import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const bodyFont = 'Work Sans, sans-serif'
const headingFont = 'Playfair Display, sans-serif'
//const curvedFont = 'Caveat, sans-serif'

const white = '#fff'
const primaryMain = '#054354'
const greyDark = '#535353'
const grey = '#969696'
const greyLight = '#EDE9E5'
//const greyExtraLight = '##F7F6F4'
const blueDark = '#182D37'
//const blueLight = '#96B9B6'
const green = '#91AC5E'
//const greenDark = '#2A4F3B'
//const greenMedium = '#426D56'
//const greenLight = '#567D68'
const yellow = '#E5B855'
//const orange = '#D4833A'
const red = '#B52A2A'

const imp = '!important'

const f14 = `14px`
const f15 = `15px`
const f16 = `16px`
const f18 = `18px`

const f22 = `22px`
const f24 = `24px`
const f36 = `36px`
const f40 = `40px`
const f50 = `50px`
const f66 = `66px`

const body = {
  fontSize: f14,
  color: greyDark,
  fontWeight: 400,
  lineHeight: 1.5,
  '@media (min-width: 1024px)': {
    fontSize: f16,
  },
}

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid ${greyLight} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${greyLight} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  themeFont: {
    main: bodyFont,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${bodyFont}, sans-serif`,
    h1: {
      ...heading(f50, f66, 700, 700, headingFont, headingFont, false),
    },
    h2: {
      ...heading(f22, f36, 600, 700, headingFont, headingFont, false),
    },
    h3: {
      ...heading(f24, f24, 600, 600, headingFont, headingFont, false),
    },
    h4: {
      ...heading(f22, f22, 700, 700, headingFont, headingFont, false),
    },
    h5: {
      ...heading(f15, f18, 600, 600, headingFont, headingFont, false),
    },
    h6: {
      ...heading(f18, f18, 400, 400, headingFont, headingFont, false),
    },
    body1: {
      ...body,
    },
    body2: {
      ...body,
    },
    caption: {
      fontSize: f15,
    },
    button: {
      fontSize: f14,
      lineHeight: 1,
      fontWeight: 600,
      fontFamily: bodyFont,
      '@media (min-width: 1024px)': {
        fontSize: f16,
      },
    },
  },
  palette: {
    common: {
      black: primaryMain, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: primaryMain,
    },
    success: {
      main: green,
    },
    error: {
      main: red,
    },
    warning: {
      main: '#FF8333',
    },
    divider: '#f0f0f0',
    primaryTransparent: {
      main: primaryMain,
      light: greyLight,
    },
    secondaryTransparent: {
      main: primaryMain,
      light: greyLight,
    },
    ternary: {
      main: primaryMain,
      light: '#F1FAFF',
    },
    accent: {
      main: primaryMain,
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: '#EDE9E5',
    greyText: grey,
  },
  shadows: [
    'none',
    '0px 0px 15px rgba(46, 46, 46, 0.1)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.page': {
          '&--home': {
            '& .banner': {
              background: `${greyLight} !important`,
              height: '24px',
              '& .MuiTypography-root': {
                fontFamily: bodyFont,
                fontSize: '12px !important',
                fontWeight: 400,
                color: primaryMain,
                textTransform: 'uppercase',
              },
            },
            '& .header--top': {
              top: '24px !important',
            },
          },
          '& .account': {
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                marginTop: '80px !important',
              },
          },
          '& .account-informations': {
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                padding: '10px !important',
              },
          },
        },
        '.select': {
          ...customSelect,
        },
        '.hero__home': {
          height: '600px',
          minHeight: '62vh !important',
          '& .ctas': {
            '& span': {
              color: `${white} ${imp}`,
            },
          },
        },
        '.header': {
          '&__logo': {
            height: `60px ${imp}`,
          },
          '&__layout': {
            height: `80px ${imp}`,
          },
          '&__icons': {
            borderLeft: '0 !important',
            '& p': {
              color: greyDark,
              fontSize: `${f14} ${imp}`,
              fontWeight: 400,
            },
            '& .faq__icon': {
              marginRight: '0.3rem',
            },
          },
          '& .menu': {
            '&--primary': {
              '& .menu__list--primary': {
                '& .menu__item': {
                  '&:after': {
                    content: 'none',
                  },
                  '& .menu__link': {
                    fontWeight: 700,
                  },
                },
              },
            },
            '&__list': {
              gap: `16px ${imp}`,
            },
          },
          '& .basket': {
            marginLeft: '1.5rem',
            '@media (min-width: 480px)': {
              minHeight: '0% !important',
            },
            '& span': {
              color: greyDark,
              fontSize: `${f14} ${imp}`,
              fontWeight: 400,
            },
          },
        },
        '.daterange': {
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              paddingLeft: '30px !important',
            },
        },
        '.connected-form__button': {
          '& span': {
            color: `${white} ${imp}`,
          },
        },
        '.instaWall__instaButton': {
          '& span': {
            color: `${white} ${imp}`,
          },
        },
        '.basket__btn': {
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              '& p': {
                display: 'none',
              },
            },
        },
        '.pack-add-to-cart': {
          '& button': {
            backgroundColor: `${primaryMain} ${imp}`,
            '&:hover': {
              backgroundColor: `${primaryMain} ${imp}`,
            },
            '& span': {
              color: `${white} ${imp}`,
            },
          },
        },
        '.section-testimonials': {
          '&:before': {
            backgroundColor: `${white} ${imp}`,
          },
        },
        '.section-testimonials__title': {
          fontSize: `${f40} ${imp}`,
          marginBottom: `60px ${imp}`,
          marginTop: `0 ${imp}`,
          color: primaryMain,
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              fontSize: `${f24} ${imp}`,
              fontFamily: `${headingFont} ${imp}`,
            },
        },
        '.testimonial__icon': {
          border: 'none !important',
          position: 'absolute',
          top: '-7%',
          right: '30%',
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              marginBottom: '3rem',
            },
        },
        '.testimonial__title': {
          marginTop: `-25px ${imp}`,
          color: `${primaryMain} ${imp}`,
        },
        '.testimonial': {
          backgroundColor: `${greyLight} ${imp}`,
          borderRadius: `0 ${imp}`,
          height: '100%',
          padding: `80px 20px 10px 20px ${imp}`,
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              padding: `103px 20px 73px 20px ${imp}`,
              margin: `45px auto 33px ${imp}`,
            },
        },
        '.faq': {
          boxShadow: 'none !important',
          borderRadius: '0 !important',
          '& .MuiTypography-root': {
            boxShadow: 'none',
            borderRadius: '0',
            borderBottom: `1px solid ${greyDark}`,
            '&>button': {
              padding: '8px !important',
              marginBottom: '8px !important',
              marginTop: '8px !important',
              '&[aria-expanded=true]': {
                borderRadius: '0',
                backgroundColor: greyLight,
                borderColor: greyLight,
                '& p': {
                  color: greyDark,
                },
              },
              '& svg': {
                border: 'none !important',
              },
            },
          },
          '&__question': {
            '& .MuiButton-label': {
              color: `${greyDark} ${imp}`,
            },
            '&[aria-expanded=true]': {
              '& .MuiButton-label': {
                color: `${white} ${imp}`,
              },
              '& svg': {
                color: primaryMain,
              },
            },
          },
          '& .faq__answer': {
            border: 'none !important',
            padding: '8px !important',
            marginBottom: '8px !important',
            marginTop: '8px !important',
          },
        },
        '.faq-page__anchor': {
          '& span': {
            color: `${white} ${imp}`,
          },
        },
        '.wrapper': {
          '& p': {
            fontSize: `${f40} ${imp}`,
          },
          '& .hero__home-box': {
            padding: '0 0 5rem 5rem',
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                padding: '0 0 15rem 0',
                '& p': {
                  fontSize: `${f24} ${imp}`,
                },
              },
            '@media only screen and (max-width: 380px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                padding: '0 0 5rem 0',
              },
          },
        },
        '.discover__media': {
          paddingBottom: '70% !important',
          '& img': {
            maxWidth: '688px',
            maxHeight: '516px',
          },
        },
        '.discover-product__content': {
          background: `${white} ${imp}`,
        },
        '.account-cancel_subscription': {
          '& .MuiButton-label': {
            color: `${red} ${imp}`,
          },
        },
        '.account-cancel_subscription_close': {
          '& .MuiButton-label': {
            color: `${white} ${imp}`,
          },
        },
        '.section-infos__cta': {
          '& .MuiButton-label': {
            color: `${white} ${imp}`,
          },
        },
        '.section-discover': {
          padding: '100px 0 0 0 !important',
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              padding: '50px 0 0 0 !important',
            },
          '& .MuiContainer-root': {
            '& .fullwidth': {
              left: '50%',
              '& #swiper-products__prev': {
                '& svg': {
                  display: 'none',
                },
              },
              '& #swiper-products__next': {
                '& svg': {
                  display: 'none',
                },
              },
            },
          },
        },
        '.section-hiw': {
          padding: '0 !important',
          '& .MuiContainer-maxWidthLg': {
            maxWidth: '1200px !important',
          },
        },
        '.section-hiw__title': {
          paddingTop: '0 !important',
        },
        '.section-hiw__spacing': {
          padding: '30px 0 0 0 !important',
          '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'baseline',
            '&:nth-child(4)': {
              marginLeft: '12rem',
              '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
                {
                  marginLeft: '0',
                },
            },
            '&:nth-child(5)': {
              '& img': {
                maxHeight: '55px',
              },
            },
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                '&:nth-child(1)': {
                  '& img': {
                    maxHeight: '30px',
                  },
                },
                '&:nth-child(2)': {
                  '& img': {
                    maxWidth: '50px',
                    position: 'relative',
                    right: '20%',
                  },
                },
                '&:nth-child(3)': {
                  '& img': {
                    maxWidth: '30px',
                  },
                },
              },
          },
        },
        '.swiper-slide': {
          '&: .testimonial': {
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                height: '360px !important',
              },
          },
        },
        '.hiw__icon': {
          '& img': {
            width: '100%',
            height: '100%',
            maxWidth: '80px',
            maxHeight: '45px',
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                marginLeft: '20px',
                marginRight: '38px',
                maxWidth: '30px',
              },
          },
        },
        '.footer__bottomRow1Col': {
          '& button': {
            backgroundColor: 'transparent !important',
            borderColor: `${primaryMain} ${imp}`,
            '&:hover, &:focus, &:active, &.selected': {
              backgroundColor: `${greyLight} ${imp}`,
            },
            '& span': {
              color: `${primaryMain} ${imp}`,
            },
          },
        },
        '.section-faq': {
          '& .faq__item': {
            marginBottom: '0',
            '& .faq__answer': {
              border: 'none',
              borderRadius: '0',
              marginBottom: '0',
            },
            '&:last-child': {
              marginBottom: '2rem',
            },
          },
          '& .faq': {
            boxShadow: 'none',
            borderRadius: '0',
            borderBottom: `1px solid ${greyDark}`,
            '& .faq__question': {
              marginBottom: '0',
              '&[aria-expanded=true]': {
                borderRadius: '0',
                backgroundColor: greyLight,
                borderColor: greyLight,
                '& p': {
                  color: greyDark,
                },
              },
              '& .faq__btn': {
                border: 'none',
                borderRadius: '0',
                color: greyDark,
              },
            },
          },
          '& .section-faq__cta': {
            '& span': {
              color: `${white} ${imp}`,
            },
          },
        },
        '.quantitySelector': {
          '&__btn': {
            '&--minus': {
              '& .MuiButton-label': {
                color: `${primaryMain} ${imp}`,
              },
            },
          },
        },
        '.packCard-title__availability': {
          backgroundColor: `${yellow} ${imp}`,
          borderRadius: '0 !important',
        },
        '.packFashion': {
          boxShadow: 'none !important',
          borderRadius: '0 !important',
          height: '100%',
          border: `1px solid ${greyLight}`,
          '& .MuiGrid-root': {
            height: '100%',
          },
        },
        '.pack__fashionContent': {
          '@media (min-width: 480px)': {
            padding: '15px !important',
          },
        },
        '.pack__title': {
          color: `${primaryMain} ${imp}`,
        },
        '.pack__link': {
          backgroundColor: `${primaryMain} ${imp}`,
          border: `1px solid ${primaryMain}`,
          '& span': {
            color: `${white} ${imp}`,
          },
          '&:hover': {
            backgroundColor: `${primaryMain} ${imp}`,
          },
        },
        '.pack-choose-button': {
          '& button': {
            backgroundColor: `${primaryMain} ${imp}`,
            '&:hover': {
              backgroundColor: `${primaryMain} ${imp}`,
            },
            '& span': {
              color: `${white} ${imp}`,
            },
          },
        },
        '.product__container': {
          '& .shop__planner': {
            marginTop: '30px !important',
            '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
              {
                marginTop: '10px !important',
                marginBottom: '10px !important',
              },
          },
        },
        '.product-tab': {
          '& span': {
            color: `${primaryMain} ${imp}`,
          },
        },
        '.productCard__add': {
          '& svg': {
            fill: white,
          },
        },
        '.cart-badge': {
          right: '35px !important',
          bottom: '35px !important',
        },
        '.cart-next': {
          ' & span': {
            color: `${white} ${imp}`,
          },
        },
        '.menu-mobile__item': {
          fontSize: '21px !important',
        },
        '.menu-mobile__content': {
          backgroundColor: `${greyLight} ${imp}`,
        },
        '.funnel': {
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              marginTop: '30px',
            },
        },
        '.shipping-infos__start': {
          '@media only screen and (max-width: 430px) and (max-height: 932px) and (-webkit-max-device-pixel-ratio: 3)':
            {
              width: '70% !important',
            },
        },
        '.backInStockAlert__button': {
          '& span': {
            color: `${white} ${imp}`,
          },
          '&:hover': {
            backgroundColor: `${primaryMain} ${imp}`,
          },
          '&:disabled': {
            backgroundColor: `${white} ${imp}`,
            '& span': {
              color: `${primaryMain} ${imp}`,
            },
          },
        },
        '.react-datepicker-wrapper': {
          '& .MuiButton-label': {
            color: `${primaryMain} ${imp}`,
          },
        },
        '.product__bottom-item': {
          fontSize: `inherit ${imp}`,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '8px 20px',
        borderRadius: 0,
      },
      label: {
        fontWeight: 400,
        fontSize: `${f16} ${imp}`,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
        fontSize: f16,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        backgroundColor: primaryMain,
        border: `1px solid ${primaryMain}`,
        '& span': {
          color: white,
        },
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${blueDark} ${imp}`,
        },
      },
      containedSecondary: {
        backgroundColor: white,
        '& span': {
          color: primaryMain,
        },
        border: `1px solid ${primaryMain}`,
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: `${greyLight} ${imp}`,
        },
        '&:disabled': {
          backgroundColor: `${greyLight} ${imp}`,
          border: 'none',
        },
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
    },
    MuiTab: {
      root: {
        padding: '8px 20px',
        borderRadius: `0 !important`,
      },
      wrapper: {
        color: white,
        fontWeight: 400,
        fontSize: `${f16} ${imp}`,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 10,
      },
    },
    MuiSelect: {
      root: {
        color: primaryMain,
      },
      selectMenu: {
        '&:focus': {
          background: '#fff',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: '#000',
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: f15,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: f15,
        paddingRight: 10,
      },
      colorPrimary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
